<template>
  <div>
   <settings/>
  </div>
</template>
<script>
import Settings from "@/components/Settings";
export default {
  components: {
    Settings
  },
  data() {
    return {};
  }
};
</script>
<style>
div.card-header {
  padding: 0.75rem 1.25rem;
  color: #ffffff !important;
  margin-bottom: 0;
  background-color: #000000 !important;
  border-bottom: 1px solid #c8ced3;
}
h3.popover-header {
  color: #ffffff;
  background-color: #000000;
}
</style>
